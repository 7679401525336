<script>
export default {
    
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">{{ new Date().getFullYear()}} © CloudKitch Pvt Ltd.</div>
           
        </div>
    </div>
</footer>
</template>