<script>
//import simplebar from "simplebar-vue";
// let localStorageArr = localStorage.getItem('user');
// let storageData = JSON.parse(localStorageArr);

export default {
  components: {
       //simplebar
  },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/china.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
      loginUserType : JSON.parse(localStorage.getItem('loginType')),
      restLogo : this.$storageData.profile,
      isCheckedIn:0,
    };
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    if(this.$loggedRole=="restaurant"){
        this.getCheckinData();
    }
    

  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    logoutUser() {
      this.logout();
      this.$router.push({
        path: "/account/login",
      });
    },
    restaurantCheckIn($event){
      let isCheckedIn = $event.target.checked;
   
            this.axios.post("restaurantCheckIn", {
            restBranchID:  this.$storageData.profile.restBranchID, empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
              isCheckedIn:isCheckedIn
          }).then((result)=>{
                    this.isCheckedIn = result.data.data.isReadyToAcceptOrder;         
            });
    },
    getCheckinData(){

            this.axios.post(this.$loggedRole+"/getCheckinData", {
            restBranchID:  this.$storageData.profile.restBranchID,
          }).then((result)=>{
                    this.isCheckedIn = result.data.data.isReadyToAcceptOrder;   
                     
            });
           
    }
  },
};
</script>

<template>

  <header id="page-topbar">
    <div class="navbar-header">
      <!-- <div v-if="this.$loggedRole=='admin'" class="row"></div> -->
    <div v-if="this.$loggedRole=='restaurant'" class="row" style="width: 50%;">
        <div class="col-md-3" v-if="this.restLogo.logo!=''">
            <img :src="this.restLogo.logo"  style="height: 70px;padding-left: 18px;padding-top:8px;padding-bottom:8px;max-width: 178px;
    width: 100%;
    object-fit: contain;"/>
        </div>
         <div class="col-md-5" v-else>
           <h6 style="margin-top: 21px;margin-left: 13px;">{{restLogo.restName}}</h6>
        </div>
         <div class="col-md-2" style="margin-top: 15px;" v-if="this.$storageData.profile.empTypeID==3">
             <div class="form-check form-switch form-switch-lg mb-3" dir="ltr" >
                  
                  <input  class="form-check-input checkInSwitch" type="checkbox"  :checked="this.isCheckedIn==1"
                  id="autoSizingCheck" title="Ready for orders" @change="restaurantCheckIn($event);">
             </div>
        </div>  
        <div class="col-md-4" style="margin-top: 19px;" v-if="this.$storageData.profile.empTypeID==3 && restLogo.branchName">
          <p style="font-size: 14px;
    font-weight: 500;
    color: grey;">{{restLogo.branchName}} </p>
        </div>  
    </div>
     <div v-else-if="this.$loggedRole=='corporate'" class="row">
          <div class="col-md-8" v-if="this.restLogo.logo!=''">
                <img :src="this.restLogo.logo"  style="height: 70px;padding-left: 18px;padding-top:8px;padding-bottom:8px;max-width: 178px;
    width: 100%;
    object-fit: contain;"/>
            </div>
            <div class="col-md-12" v-else>
              <h6 style="margin-top: 21px;margin-left: 13px;">{{restLogo.corporateName}}</h6>
            </div>
     </div>

     <div v-else-if="this.$loggedRole=='principle'" class="row">
          <div class="col-md-8" v-if="this.restLogo.logo!=''">
                <img :src="this.restLogo.logo"  style="height: 70px;padding-left: 18px;padding-top:8px;padding-bottom:8px;max-width: 178px;
    width: 100%;
    object-fit: contain;"/>
            </div>
            <div class="col-md-12" v-else>
              <h6 style="margin-top: 21px;margin-left: 13px;">{{restLogo.principleName}}</h6>
            </div>
     </div>
      <!-- <div class="d-flex">
      

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>


      </div> -->
      <div class="row"></div>
      <div class="d-flex">      
    <!-- <b-dropdown
          variant="white"
          class="d-none d-lg-inline-block ms-1"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-apps"></i>
          </template>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/github.png" alt="Github" />
                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.bitbucket") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/dribbble.png"
                    alt="dribbble"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.dribbble") }}</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>{{ $t("navbar.dropdown.site.list.dropbox") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/mail_chimp.png"
                    alt="mail_chimp"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.mailchimp") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/slack.png" alt="slack" />
                  <span>{{ $t("navbar.dropdown.site.list.slack") }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-dropdown> -->

        <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
            @click="initFullScreen"
          >
            <i class="uil-minus-path"></i>
          </button>
        </div> -->

        <!-- <b-dropdown
          variant="white"
          class="dropdown d-inline-block"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
          <template v-slot:button-content>
            <i class="uil-bell"></i>
            <span class="badge bg-danger rounded-pill">3</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h5>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">{{
                  $t("navbar.dropdown.notification.subtext")
                }}</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px" data-simplebar>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="uil-shopping-basket"></i>
                  </span>       
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.order.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.order.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.order.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-3.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.james.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.james.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.james.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="uil-truck"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.item.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.item.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.item.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href class="text-reset notification-item">
              <div class="media">
                <img
                  src="@/assets/images/users/avatar-4.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    {{ $t("navbar.dropdown.notification.salena.title") }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ $t("navbar.dropdown.notification.salena.text") }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ $t("navbar.dropdown.notification.salena.time") }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-link font-size-14 btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="uil-arrow-circle-right me-1"></i>
              {{ $t("navbar.dropdown.notification.button") }}
            </a>
          </div>
        </b-dropdown> -->



      <!-- <router-link to="/" class="logo logo-dark"> -->
        <span class="logo-sm">
          <img src="@/assets/images/logo.svg" alt height="22" style="height:50px;" />
        </span>
        <!-- <span class="logo-lg">
          <img src="@/assets/images/logo.svg" alt height="20" style="height:50px;" />
        </span> -->
      <!-- </router-link> -->
</div>
 
    </div>
</header>

</template>
<style>
.checkInSwitch:checked{
  background-color:#FFC60C !important;
    border-color: #FFC60C !important;
}
</style>